.wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 500;
    font-family: var(--font-family);
    cursor: pointer;
    background-color: var(--white);
    border: 1px solid transparent;
    user-select: none;
    line-height: normal;
    + .wrapper {
        margin-left: 8px;
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    &:hover {
        background-color: #F4F6F8;
    }
}

.icon + .title, .title + .icon {
    margin-left: 8px;
}

.title {
    text-align: left;
}

.icon {
    display: inline-block;
    width: 20px;
    text-align: center;
}

.small {
    padding: 6px 10px;
}

.large {
    padding: 14px 16px;
    min-width: 140px;
}

.rounded {
    border-radius: 999px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 6%);
    border-color: rgba(22, 24, 35, 0.12);

    &:hover {
        border-color: rgba(22, 24, 35, 0.2);
        background-color: rgba(22, 24, 35, 0.03);
    }
}

.primary {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
    &:hover {
        border-color: var(--primary);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #fe2c55;
    }
}

.outline {
    --color:var(--primary-code);
    color: rgb(var(--color));
    border-color: currentColor;
    background-color: #fff;
    &:hover {
        border-color: currentColor;
        background-color: rgba(var(--color), 0.05);
    }
}
.buttonLoading {
    width: auto!important;
    p {
        margin: 0!important;
        width: 20px!important;
        height: 20px!important;
        border-width: 3px!important;
    }
}
.background {
    --color:var(--primary-code);
    color: #fff;
    border-color: currentColor;
    background-color: rgb(var(--color));
    &:hover {
        border-color: currentColor;
        background-color: rgba(var(--color), 0.8);
    }
    .buttonLoading {
        p {
            border-bottom-color:rgb(var(--color));
        }
    }
}

.white {
    box-shadow: 0 2px 8px rgb(0 0 0 / 6%);
    border-color: rgba(22, 24, 35, 0.12);
    background-color:#fff;
    color: #000;
    &:hover {
        border-color: rgba(22, 24, 35, 0.2);
        background-color: rgba(22, 24, 35, 0.03);
    }
    .buttonLoading {
        p {
            border-bottom-color:#000!important;
        }
    }
}

.red {
    --color:var(--red-code);
}
.blue {
    --color:var(--blue-code);
}
.green {
    --color:var(--green-code);
}
.yellow {
    --color:var(--yellow-code);
}
.noneBorder {
    border-color: transparent;
}