.salePointGroup {
    border: 1px solid #ededed;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    .item {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        .heading {
            background-color: var(--primary);
            color: #fff;
            font-size: 15px;
        }
        > div {
            padding: 10px;
            border-top: 1px solid #ededed;
            height: 100%;
        }
        .txt {
            text-align: center;
            font-size: 15px;
            span {
                text-align: center;
                font-size: 20px;
                font-weight: bold;
            }
        }
    }
}
