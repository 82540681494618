.list-action {
    .action-item {
        display: block;
        margin-left: 0!important;
        width: 100%;
        span:first-child {
            width:30px;
        }
        i {
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius:50%;
            color:#fff;
            background-color: var(--primary);
            margin-right: 10px;
        }
    }
}

.profile {
    width:800px;
    max-width:100%;
    margin: 0 auto;
}
.profile-heading {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    .profile-avatar {
        position: relative;
        align-items: center;
        background-color: #fff;
        display: flex;
        justify-content: center;
        .profile-img {
            position: relative;
            border-radius: 50%;
            height: 9em;
            object-fit: cover;
            width: 9em;
        }
        img {
            border-radius: 50%;
            height: 9em;
            object-fit: cover;
            width: 9em;
        }
    }
    .profile-name {
        width: 100%;
        text-align: center;
        .profile-name-main {
            font-size: 2.8rem;
            font-weight: 700;
        }
        .profile-name-mini {
            font-size: 1.5rem;
            color:#999;
            margin-bottom: 16px;
        }
    }
    .loading {
        position: absolute;
        top: 0; left:0;
        width: 100%; height: 100%;
        background-color: rgba(0,0,0,0.5);
    }
    .file-inputs {
        position: absolute;bottom: 10px;right: 10px;
        width: 40px; cursor: pointer;
        input {
            position: relative;
            text-align: right;
            opacity: 0;
            z-index: 2;
            cursor: pointer;
            height: 40px;
            max-width: 40px;
        }
        button {
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 40px;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #fff;
            background-color: rgb(var(--primary-code), 0.3);
            font-size: 25px;
            cursor: pointer;
            border-radius: 50%;
            border: none;
            outline: none;
            // padding: 1em;
            transition: background-color 0.4s;
            box-shadow: 0 8px 24px rgba(149, 157, 165, 0.5);
        }

        &:hover {
            button {
                background-color: rgb(var(--primary-code), 1);
            }
        }
    }
}

h3 {
    font-size:2rem!important;
}

.group-info {
    margin-bottom: 10px;
    .label {
        display: block;
        font-weight:bold;
        margin-bottom: 10px;
    }
}