.items {
    padding:10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width:600px) {
        grid-template-columns: repeat(1, 1fr);
    }
}
.item {
    padding:10px;
    background-color: #f5f5f5;
    border-radius: 10px;
    display: flex; gap:10px;
    align-items: center;
    .icon {
        border-radius: 10px;
        font-size: 30px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.08);
        padding: 10px 20px;
    }
    .info {
        p {
            font-size:13px;
            color:rgba(0, 0, 0, 0.2);
        }
    }
    &:hover {
        background-color:var(--primary);
        color:#fff;
        .info {
            h3 {
                color:#fff;
            }
            p {
                font-size:13px;
                color:rgba(255, 255, 255, 0.5);
            }
        }
    }
}
