.wrapper {
    width:800px;
    padding: 0 50px;
    margin: 0 auto;
    max-width: 100%;
    overflow: auto;
    max-height: 86vh;
    @media (max-width:600px)
    {
        padding: 0 0px;
    }
}
.check-domain-title {
    &-mini {
        margin-top: 20px;
    }
    &-big {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 35px;
        letter-spacing: 1px;
        color: #ff0000;
        position: relative;
        &.green {
            color: #7fca27;
        }
    }
}
.check-domain-wrapper {
    padding-left: 0;
    position: relative;
    margin-bottom: 20px;
}