.declaration-domain-international {
    display: none;
}
@media print {
    .declaration-domain-international {
        font-family: "Times New Roman", 'sans-serif';
        font-size: 16px;
        display: block;
        padding:20px;
        line-height: 30px;
        h1 {
            text-align: center;
            margin-top: 20px;
            font-size: 25px;
        }
        .title-mini {
            margin:0;
            text-align: left;
            font-size: 14px;
        }


        table {
            width: 100%;
            margin: 10px 0;
        }

        table tr td {
            border: 1px solid #000;
            padding: 10px;
        }

        table tr td p {
            margin-top: 0;
            margin-bottom: 5px;
            font-size: 14px;
        }
    }
}