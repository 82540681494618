.message-wrapper {
    --message-bg: #f3f4f9;
    --message-bg-2: #3d42df;
    --message-text: #2c303a;
    display: flex;
    padding: 16px 0 0 0;
    .profile-picture {
        border-radius: 8px;
        width: 32px;
        height: 32px;
        overflow: hidden;
        margin-right: 12px;
        flex-shrink: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .name {
        margin: 0;
        line-height: 16px;
        font-size: 12px;
        font-weight: 700;
        color: var(--message-text);
    }
    .text-inner {
        margin-top: 8px;
        line-height: 1.5;
        color: var(--message-text);
        background-color: var(--message-bg);
        padding: 8px 16px;
        border-radius: 0 12px 12px 12px;
        word-break: break-word;
        animation: popup 0.2s ease forwards;
        transition: all 0.2s ease;
        transform-origin: 0%;
        .text-link {
            text-decoration: underline;
            font-size: 1.6rem;
            color: var(--text-black);
        }
    }
    .text-inner.message-photo {
        padding: 0;
    }
    .message-photo {
        min-width: 60px;
        min-height: 60px;
        max-width: 220px;
        max-height: 220px;
        object-fit: cover;
        cursor: pointer;
        border: 1px solid var(--border-color);
        transition: all 0.1s ease;
        background-color: #fff !important;

        &:hover {
            filter: brightness(0.95);
        }
    }
    &.group {
        padding:0;
        .profile-picture, .name {
            display: none;
        }
        .text-inner {
            margin-left:45px;
        }
    }
    &.reverse {
        flex-direction: row-reverse;
        .text-inner {
            margin-left:0;
            background-color: var(--primary);
            color: #fff;
        }
        .profile-picture, .name {
            display: none;
        }
    }
}
.message-wrapper.time {
    .message-content {
        width:100%; text-align: center;
        .message-time {
            display: inline-block;
            font-size:12px;
            padding:2px 15px;
            background-color:var(--message-bg);
            color:var(--message-text);
            border-radius:20px;
        }
    }
}