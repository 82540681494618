.project-info {
    padding:15px;
    box-shadow: 0 6px 30px rgba(0,0,0,0.05);
    position: relative;
    z-index: 1;
    .name {
        font-size:18px;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: capitalize;
        margin-right:10px;
    }
    @media(max-width: 600px) {
        display: block;
    }
}
.project-heading {
    .excerpt {
        font-size: 12px;
        color:#ccc;
    }
}
.project-task {
    padding-bottom: 10px;
    .name {
        text-transform: uppercase;
    }
}