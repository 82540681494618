.wrapper {
    width: 100%;
    min-height: var(--header-height);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--header-bg);
}
.inner {
    max-width:100%;
    width:var(--wrapper-width);
    min-height:var(--header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.right {
    display: flex;
    gap:10px;
    align-items: center;
}

//Notification icon
.notification-icon {
    display: flex;
    gap:10px;
    .icon {
        position: relative;
        height: 30px; width: 30px; line-height: 30px;
        background-color: rgba(255,255,255,0.2);
        color:#fff;
        border-radius: 50%;
        fill:currentColor;
        display: flex; align-items: center; justify-content: center;
        cursor: pointer;
        &:hover {
            background-color: rgba(255,255,255,0.5);
        }
    }
    .number {
        position: absolute;
        text-align: center;
        font-size: 10px;
        z-index: 1;
        font-weight: 700;
        border-radius: 100%;
        -webkit-animation: pulse 0.75s infinite;
        -webkit-transform-origin: 50% 4px;
        -moz-animation: pulse 0.75s infinite;
        -moz-transform-origin: 50% 4px;
        animation: pulse 0.75s infinite;

        line-height: 15px;
        color: #fff;
        top: 0px;
        right: 0px;
        width: 10px;
        height: 10px;
        background-color: var(--primary);
    }
    @-webkit-keyframes pulse {
        0% {
            box-shadow: 0 0 0 0.2em #cfffd7a3;
        }
        25% {
            box-shadow: 0 0 0 0.05em #c20a0a;
        }
        50% {
            box-shadow: 0 0 0 0.375em #c20a0a;
        }
        75% {
            box-shadow: 0 0 0 0.2em #cfffd7a3;
        }
        100% {
            box-shadow: 0 0 0 0.3em #c20a0a;
        }
    }
    @-moz-keyframes pulse {
        0% {
            box-shadow: 0 0 0 0.2em #cfffd7a3;
        }
        25% {
            box-shadow: 0 0 0 0.05em #c20a0a;
        }
        50% {
            box-shadow: 0 0 0 0.375em #c20a0a;
        }
        75% {
            box-shadow: 0 0 0 0.2em #cfffd7a3;
        }
        100% {
            box-shadow: 0 0 0 0.3em #c20a0a;
        }
    }
    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0.2em #cfffd7a3;
        }
        25% {
            box-shadow: 0 0 0 0.05em #edfff0;
        }
        50% {
            box-shadow: 0 0 0 0.375em #edfff0;
        }
        75% {
            box-shadow: 0 0 0 0.2em #cfffd7a3;
        }
        100% {
            box-shadow: 0 0 0 0.3em #edfff0;
        }
    }
}

//Account
.account {
    display: flex; align-items: center; gap:10px;
    padding:5px; transition: all 0.5s ease;
    &:hover {
        background-color: rgba(0,0,0, 0.1);
        border-radius: 5px;
        cursor: pointer;
    }
    .avatar {
        width: 30px; height:30px; overflow: hidden; border-radius: 50%;
        img {
            width: 100%; height:100%; object-fit: cover;
        }
    }
    .name {
        color:#fff;
    }
}
.account-action {
    .button {
        margin-left:0;
        justify-content:left;
    }
    hr {
        margin: 0;
    }
}

//Logo mobile
.mobile-logo {
    color:#fff;
    font-weight:bold;
    font-size:25px;
    letter-spacing: 2px;
}