.modal-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    &.modal-show {
        display: block;
    }
    &.modal-hide {
        display: none;
    }
    &.modal-sm {
        .modal {
            width:300px;
        }
    }
    &.modal-lg {
        .modal {
            width:800px;
        }
    }
    &.modal-xl {
        .modal {
            width:1140px;
        }
    }
}

.overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--overlay);
    z-index: 98;
    animation: showOverlay 0.4s ease forwards;
}

.modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 548px;
    max-width:100%;
    min-height: 170px;
    background-color: #fff;
    border: 1px solid rgb(0 0 0 / 12%);
    box-shadow: rgb(0 0 0 / 12%) 0 4px 16px;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 20px;
    overflow: hidden;
    z-index: 99;
    //animation: showModal 0.4s ease forwards;
}

@media (max-width: 768px) {
    .modal {
        top: 0;
        left: 50%;
        height: 100vh;
        transform: translate(-50%, 0);
    }
}

.header-box {
    display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;
    padding: 20px 20px 0 20px;
    .close {
        width: 50px; min-width: 50px;
    }
    .title {
        text-align: center;
        font-weight: bold;
        color: var(--text-black);
    }
    p {
        margin-bottom: 0;
        color:#ccc; font-size: 1.3rem;
    }
    hr {
        flex: 0 0 100%;
        width: 100%;
    }
}

.content {
    flex: 1;
    padding:20px;
    max-height: 85vh;
    overflow: auto;
    margin-bottom: 70px;
}

hr {
    border:none;
    height: 1px;
    background-color:rgba(0,0,0,0.1);
    margin: 20px 0;
}

.cancel-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    font-size: 1.8rem;
    color: var(--dark-gray);
    background-color: var(--light-gray);
    border-radius: 50px;
    cursor: pointer;
    &:hover {
        background-color: var(--gray);
    }
}


@keyframes showModal {
    from {
        bottom: -100px;
        left: 50%;
        opacity: 0;
    }
    to {
        bottom: 50%;
        left: 50%;
        opacity: 1;
    }
}

@keyframes showOverlay {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}