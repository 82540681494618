@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;700&family=Oooh+Baby&family=Oswald:wght@200;400;500;600&display=swap');
html, body {
    background-color: #EFEFFB; overflow: auto!important;
}
:root {
    --theme-user-info-color:#284BE5;
}
@media (min-width: 1200px) {
    .container {
        width: 1200px;
        margin: 0 auto !important;
    }
}

.user-information {
    background-color: #070918;
    padding:100px 100px 200px 100px;
    font-size: 14px;
    border-radius: 0 0 300px 0;
}
.user-information-header {
    display: grid; grid-template-columns: repeat(2, 1fr);
    color: #fff; font-style: inherit;
    .user-header-content {
        .txt-hello {
            font-family: 'Oooh Baby', cursive;
            font-size: 3em;color: #fff;
            line-height: 1em;
        }
        .txt-name {
            font-size: 4em; color: #fff; line-height: 1.5em;
            font-weight: bold;
            strong {
                line-height: 1.5em;
                font-weight: bold;
                font-size: 1.5em;
                color: var(--theme-user-info-color);
            }
        }
        .txt-office {
            display: flex; align-items: center; gap:10px;
            padding:20px 0 20px 0;
            font-size: 1.5em; font-weight: bold; color: #fff;
            position: relative;
            text-transform: uppercase;
            &::before {
                content: ''; width: 100px;
                height: 1px; background-color: var(--theme-user-info-color);
            }
        }
        .txt-des {
            font-size: 1.3em; line-height: 1.5em;
        }
        .item-list {
            display: grid; grid-template-columns: 1fr 1fr; gap:10px;
            margin-top: 20px;
            .item {
                .title {
                    font-weight: bold; font-size: 1.5em;
                    margin-bottom: 10px;
                }
                .des {
                    color: #514d6a; font-size: 1.2em;
                }
            }
        }
    }
    .user-header-avatar {
        display: flex; justify-content: center;
        .user-avatar-border {
            display: inline-block; padding: 30px;
            border-radius: 50%;
            border: 3px solid var(--theme-user-info-color);
            text-align: center;
            position: relative;
            z-index: 6; max-height: 460px;
            &::before, &::after {
                content: '';
                width: 200px; height: 200px; border-radius: 50%;
                position: absolute;z-index: 8;
            }
            &::before {
                background-color: #0D112A;
                bottom: -30px;left: -30px;
                animation: move2 3s linear infinite;
            }
            &::after {
                background-color: #ffff;
                top: -30px;right: -30px;
                animation: move3 4s linear infinite;
            }
            .circle {
                width: 60px; height: 60px; background-color: #ffffff42; border-radius: 50%;
                position: absolute; bottom: 200px;left: 200px;z-index: 7;
                animation: rot 6s linear infinite;
                overflow: hidden;
                img {
                    max-width:100%;
                }
            }
        }
        .user-img {
            background-color: #ccc;
            width: 400px; height: 400px;
            border-radius: 50%;
            overflow: hidden;
            position: relative; z-index: 9;
            img {
                height: 100%; width: 100%; object-fit: cover;
            }
        }
    }
}

.user-content {
    width: 500px; max-width: 100%;
    margin: -100px auto 0;
    border-radius: 30px;
    padding:50px;
    background-color: #fff;
    position: relative;
    &::before {
        content:'';
        width: 100px; height: 10px; background-color: var(--theme-user-info-color);
        position: absolute; top:-5px; left: 50%;
        margin-left: -50px;
    }
    .info {
        display: flex; gap:20px; font-size: 14px; color: #070918;
        padding:20px 0;
        .info-title {
            flex: 0 0 auto; width: 100px;
            font-family: 'Oswald', sans-serif;
            color: #070918; font-weight: bold; font-size: 1.4em;
        }
        .info-content {
            flex: 0 0 auto; width: calc(100% - 100px);
            font-family: 'Montserrat', sans-serif;
            color: #070918; font-size: 1.3em; line-height: 25px;
            text-align: left;
        }
    }
}
.txt-company {
    font-size: 1em; font-weight: bold;
    margin-bottom: 20px;
}
.txt-company-name {
    font-family: 'Oswald', sans-serif;
    font-size: 3em; font-weight: bold;
    margin-bottom: 20px; color: var(--theme-user-info-color);
}


@keyframes rot {
    0% { transform: rotate(0deg) translate(240px) rotate(0deg); }
    100% { transform: rotate(360deg) translate(240px) rotate(-360deg); }
}
@-webkit-keyframes move2 {
    0% {
        -webkit-transform: rotate(1deg) translate(2px, 2px);
        transform: rotate(1deg) translate(2px, 2px)
    }
    50% {
        -webkit-transform: rotate(-1deg) translate(-2px, -2px);
        transform: rotate(-1deg) translate(-2px, -2px)
    }
    to {
        -webkit-transform: rotate(1deg) translate(2px, 2px);
        transform: rotate(1deg) translate(2px, 2px)
    }
}
@keyframes move2 {
    0% {
        -webkit-transform: rotate(1deg) translate(2px, 2px);
        transform: rotate(1deg) translate(2px, 2px)
    }
    50% {
        -webkit-transform: rotate(-1deg) translate(-2px, -2px);
        transform: rotate(-1deg) translate(-2px, -2px)
    }
    to {
        -webkit-transform: rotate(1deg) translate(2px, 2px);
        transform: rotate(1deg) translate(2px, 2px)
    }
}
@-webkit-keyframes move3 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0)
    }
    20% {
        -webkit-transform: translate(5px);
        transform: translate(5px)
    }
    40% {
        -webkit-transform: translate(5px, 5px);
        transform: translate(5px, 5px)
    }
    65% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px)
    }
    65% {
        -webkit-transform: translate(5px);
        transform: translate(5px)
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0)
    }
}
@keyframes move3 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0)
    }
    20% {
        -webkit-transform: translate(5px);
        transform: translate(5px)
    }
    40% {
        -webkit-transform: translate(5px, 5px);
        transform: translate(5px, 5px)
    }
    65% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px)
    }
    65% {
        -webkit-transform: translate(5px);
        transform: translate(5px)
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0)
    }
}
.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}
/* Animation */
.parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}
.parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}
.parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}
.parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}
@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }
    100% {
        transform: translate3d(85px, 0, 0);
    }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
    .waves {
        height: 40px;
        min-height: 40px;
    }

    .user-information {
        padding: 10px 10px 100px 10px;
        font-size: 11px;
        border-radius: 0;
    }
    .user-information-header {
        grid-template-columns: repeat(1, 1fr);
        .user-header-avatar {
            .user-avatar-border {
                padding: 10px;
                &::before, &::after {
                    content: '';
                    width: 100px; height: 100px;
                }
                .circle {
                    bottom: 80px;left: 80px;
                }
            }
            .user-img {
                width: 200px; height: 200px;
            }
        }
    }

    @keyframes rot {
        0% { transform: rotate(0deg) translate(120px) rotate(0deg); }
        100% { transform: rotate(360deg) translate(120px) rotate(-360deg); }
    }

    .user-content {
        margin: -35px auto 0;padding: 25px;
    }
}