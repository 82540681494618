.wrapper {
    position: fixed; right: 25px; bottom:25px;
    width:300px;
    padding:20px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.permission {
    display: grid;
    grid-template-columns: 3fr 1fr 1.5fr;
    .icon {
        text-align: center;
        i {
            background-color: var(--green);
            border-radius:50%;
            color:#fff;
            width: 25px; height:25px; line-height: 25px;
            text-align: center;
        }
    }
}