.total-wrapper {
    .txt {
        margin: 10px 0;
        color: #8798AD;
        font-weight: bold;
        display: inline-block;
        font-size: 16px!important;
    }
    .txt-total {
        color: rgb(var(--red-code));
        font-weight: bold;
        font-size: 20px!important;
    }

    .card {
        padding:10px;
        border-radius: 10px;
        background-color: #F3F7FE;
        margin-bottom: 10px;
        .txt {
            color: #8798AD;
            font-size: 14px!important;
        }
        .txt-total {
            color: #514d6a;
            font-weight: bold;
            font-size: 16px!important;
        }
        .txt-total-big {
            font-size: 18px!important;
        }
        &.website {
            background-color: #E4FFED;
        }
    }
}

.group-name {
    font-size: 20px;
    margin-bottom: 10px;
    color:var(--primary);
}