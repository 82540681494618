.action-bar {
    width: 100%;
    height: var(--actionBar-height);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    align-items: center;
    display: flex;
}
.container {
    justify-content: space-between;
}
//Header
.header-title {
    color:var(--primary);
    font-weight: bold;
    position: relative;
    display: inline-block;
    font-size: 1.5rem;
    .header-animation {
        display: block;
        color: #fff;
        width: 100%;
        height: 40px;
        position: absolute;
        border-radius: 50%;
        overflow: hidden;
        z-index: 0;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: linear-gradient(-45deg,rgba(255,255,255,.2) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.2) 50%,rgba(255,255,255,.2) 75%,transparent 75%,transparent);
            z-index: 1;
            background-size: 50px 50px;
            -webkit-animation: 2s linear infinite ui-block-busy-state;
            animation: 2s linear infinite ui-block-busy-state;
            border-radius: 20px 8px 8px 20px;
            overflow: hidden;
        }
    }
    @media(min-width:768px) {
    }
}

@keyframes ui-block-busy-state{
    0%{background-position:0}
    100%{background-position:28px}
}