.pay-wrapper {
    display: grid; gap:10px;
    grid-template-columns: repeat(4, 1fr);
    .department {
        padding:10px;
        background-color: #B2D9FF;
        border-radius: 10px;
        text-align: center;
        .name {
            font-size:14px;
            font-weight: bold;
            color:#514d6a;
            margin-bottom: 10px;
        }
        .pay {
            font-size:20px;
            font-weight: bold;
        }
    }
}