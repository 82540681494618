.wrapper {
    background-color: var(--primary);
    height: 100vh;
    min-height: -webkit-fill-available;
    overflow: hidden;
    position: fixed;
    top:0; left:0; width: 100%;
    .wrapper-inner {
        height: 100vh;
        height: -webkit-fill-available;
    }
    @media (min-width:1900px) {
        .wrapper-inner {
            width:1900px;
            margin:0 auto;
        }
    }
}
.container {
    width:var(--wrapper-width);
    max-width:100%;
    display: flex;
}
.content {
    flex: 1;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
main {
    margin:0 10px 10px 10px;
    padding:5px;
    border-radius: 20px;
    background-color: #FBFCFF;
    overflow: hidden;
    height: calc(100vh - 60px);
    @media(max-width:600px) {
        position: absolute; top:50px; left:10px;
        height: calc(100% - 110px);
        width: calc(100% - 20px);
        margin: 0;
        overflow: hidden;
    }
}