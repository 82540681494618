.template-email {
    max-height: 500px;
    overflow: auto;
    .wrapper {
        width: 100%;
        background-color:#F3F3F3;
        padding:10px;
        font-family:'Times New Roman',sans-serif;
        line-height: 25px;
        font-size: 15px;
    }
    .wrapper-inner {
        width: 700px; margin: 0 auto;
        max-width:100%;
    }
    .header {
        overflow: hidden;
        background-color: #1C2532;
        padding:10px 10px;
        width: 100%;
        text-align: center;
        display: flex;
        .logo {
            margin-right: 10px; line-height: 13px;
            img {
                height:40px;
            }
        }
        .title {
            margin-top:7px;
            margin-bottom: 7px;
            color: #fff;
            text-align: left;
            font-size: 13px;
            font-weight: bold
        }
    }
    .header-info {
        overflow: hidden;background-color: #EBF8F3; padding:30px 10px;width: 100%;
        .inner {
            width: 600px; margin: 0 auto;
            max-width:100%;
        }
        h2 {
            color: red; text-align: center;font-family:'Arial',sans-serif;
        }
        p {
            color: #000; text-align: center;font-size: 12px;font-family:'Arial',sans-serif;
        }
    }
    .content {
        overflow: hidden;background-color: #fff; padding:30px 10px; width: 100%;
        p {
            color:#000; text-align: left;font-family:'Times New Roman',sans-serif;
        }
        table {
            width:100%; margin-bottom: 10px;
            td {
                border:1px solid #000;
                padding:6pt;
                p {
                    margin:0cm 0cm 0.0001pt;line-height:normal;
                    span {
                        font-size:10pt;color:white;
                    }
                }
            }
        }
    }
    .footer {
        overflow: hidden; background-color:#1C2532; padding:30px 10px; width: 100%; color:#ffff;
    }
}
.template-zalo {
    max-height: 500px;
    overflow: auto;
    .wrapper {
        width: 100%;
        background-color:#F3F3F3;
        padding:10px;
        font-family:'Times New Roman',sans-serif;
        line-height: 25px;
        font-size: 15px;
    }
    .wrapper-inner {
        width: 700px; margin: 0 auto;
        max-width:100%;
    }
    .content {
        margin: 30px auto;
        min-width: 350px;
        width: 350px;
        max-width:100%;
        border: 1px solid #c6c8d2;
        border-radius: 8px;
        background-color: #fff;
        padding: 16px;
        font-family: "Roboto",sans-serif!important;
        p {
            font-size: 12px;
            margin-bottom: 4px;
            color: #484a5b;
        }
        p.title {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: .02em;
        }
        ul {
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            font-size: 11px;
            margin-top: 16px;
            li {
                display: flex;
                line-height: 16px;
            }
            li+li {
                margin-top: 8px;
            }
            .key {
                min-width: 100px;
                color: #6c7089;
                word-break: break-word;
                padding-right: 10px;
                font-size: 12px;
                line-height: 20px;
                width: 40%;
            }
            .value {
                text-align: left;
                flex: 1 1;
            }
        }

        .button {
            margin-top: 16px;
            background-color: #0f2be6;
            padding: 6px;
            text-align: center;
            border-radius: 4px;
            a {
                color: #fff;
                font-size: 14px;
                line-height: 22px;
                font-feature-settings: "cpsp" on;
                font-weight: 600;
            }
        }
    }
}