.declaration-domain-international {
    display: none;
}
@media print {
    .declaration-domain-international {
        font-family: "Times New Roman", 'sans-serif';
        font-size: 16px;
        display: block;
        h1 {
            text-align: center;
            margin-top: 10px;
        }
        .title-mini {
            margin:0;text-align: center;text-decoration: underline; font-weight: bold
        }
        h1, h3 {
            font-size: 16px;
            margin-bottom: 5px;
        }

        table {
            width: 100%;
            margin: 10px 0;
        }

        table tr td {
            border: 1px solid #000;
            padding: 10px;
        }

        table tr td p {
            margin-top: 0;
            margin-bottom: 5px;
            font-size: 14px;
        }
    }
}