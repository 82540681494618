.container {
    --app-background: #eaebf5;
    --chat-background: #fff;
    --link-color: #c0c1c5;
    --navigation-bg: #fff;
    --navigation-box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
    --main-color: #3d42df;
    --message-bg: #f3f4f9;
    --message-bg-2: #3d42df;
    --message-text: #2c303a;
    --placeholder-text: #a2a4bc;
    --button-bg: #fff;
    background-color: var(--app-background);
    transition: 0.2s;
    border-radius:10px;
    padding:10px;
    height: 100%;
    .wrapper {
        background-color: var(--chat-background);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        min-height: 500px;
        height: 100%;
    }
    .area {
        flex: 1;
        overflow-y: auto;
        padding: 16px;
        max-height: 620px;
    }
    .typing-area-wrapper {
        padding: 16px;
        .typing-area {
            display: flex;
        }
    }
}

.media-wrapper {
    display: flex;
    align-items: center;
    margin-right: 8px;
    .input-image {
        display: none;
    }
}
.media-btn {
    color:var(--primary);
    width: 38px;
    height: 38px;
}

//Media preview
.media-preview {
    min-width: 60px;
    background-color: var(--light-gray);
    padding: 12px 12px 0;
    .media-preview-file-wrap {
        position: relative;
        display: inline-flex;
    }
    .media-preview-file {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 12px;
    }
    .remove-preview-file-btn {
        position: absolute;
        top: -8px;
        right: -8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        background-color: #fff;
        color: var(--text-black);
        font-size: 1.5rem;
        border-radius: 40px;
        border: 1px solid var(--medium-gray);
        cursor: pointer;
    }
}

.input-wrapper {
    flex: 1;
    background-color: var(--message-bg);
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease;

    .input {
        width: 100%;
        border: none;
        outline: none;
        padding: 9px 12px;
        caret-color: var(--primary);
        color: var(--message-text);
        flex: 1;
        background-color: transparent;
        box-shadow: none;
        &::placeholder {
            color: var(--placeholder-text);
        }
    }
}

.send-wrapper {
    display: flex;
    align-items: center;
    margin-left: 8px;
    .btnSend {
        height: 38px;
        width: 38px;
    }
}
