.kpi-wrapper {
    text-align:center;
    border: 1px solid rgba(0,0,0,0.09);
    border-radius:20px;
    overflow: hidden;
    div {
        padding:10px;
    }
    .heading {
        background-color: #0B5394;
        padding:10px;
        color:#fff;
        font-weight:bold; font-size:30px;
    }
    .revenue-total {
        font-weight:bold;
        font-size:25px;
        color:red;
    }
    .heading-mini {
        font-weight:bold; font-size:18px;
        &-blue {
            background-color: #9FC5E8;
        }
        &-red {
            background-color: #F4CCCC;
        }
        &-green {
            background-color: #B6D7A8;
        }
    }
    .revenue {
        font-weight:bold;
        font-size:18px;
    }
    .txt {
        font-size:14px; font-weight: normal;
    }
}