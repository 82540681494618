.item {
    --item-header-color:#000;
    --item-icon-bg:rgba(0, 0, 0, 0.08);
    --item-des-color:#000;
    --item-background:#f5f5f5;
    padding:10px;
    border-radius: 10px;
    display: flex; gap:10px;
    align-items: center;
    font-family: var(--font-family)!important;
    background: var(--item-background);
    background: -moz-linear-gradient(90deg, var(--item-background) 0%, var(--item-background) 23%, var(--item-background) 87%);
    background: -webkit-linear-gradient(90deg, var(--item-background) 0%, var(--item-background) 23%, var(--item-background) 87%);
    background: linear-gradient(90deg, var(--item-background) 0%, var(--item-background) 23%, var(--item-background) 87%);
    .icon {
        border-radius: 10px;
        font-size: 30px;
        text-align: center;
        background-color: var(--item-icon-bg);
        color:var(--item-des-color);
        padding: 10px 20px;
    }
    .info {
        h3 {
            font-size:15px!important;
            font-weight: 400;
            text-transform: capitalize;
            font-family: var(--font-header)!important;
            color:var(--item-header-color);
        }
        p {
            font-size:20px; font-weight: 700;
            color:var(--item-des-color);
        }
    }
    &-red {
        --item-background:rgb(255,99,132);
        --item-header-color:rgba(255, 255, 255, 0.8);
        --item-des-color:rgba(255, 255, 255, 1);
        --item-icon-bg:rgba(255, 255, 255, 0.5);
    }
    &-blue {
        --item-background:rgb(25,130,201);
        --item-header-color:rgba(255, 255, 255, 0.8);
        --item-des-color:rgba(255, 255, 255, 1);
        --item-icon-bg:rgba(255, 255, 255, 0.5);
    }
    &-green {
        --item-background:rgb(14,172,44);
        --item-header-color:rgba(255, 255, 255, 0.8);
        --item-des-color:rgba(255, 255, 255, 1);
        --item-icon-bg:rgba(255, 255, 255, 0.5);
    }
}