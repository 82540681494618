.button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: block;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height:50px;
    background-color: var(--primary);
    border-radius:50%;
    color:#fff;
    cursor: pointer;
    opacity: 0.8;
    box-shadow: -1px 1px 10px 2px #8b8b8b;
    &:hover {
        opacity: 1;
    }
    @media(max-width:600px) {
        z-index:99;
        right: calc(50% - 20px);
        width: 40px;
        height: 40px;
        line-height:40px;
    }
}