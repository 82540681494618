.notification {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: var(--notification-width);
    max-width:100%;
    height: 100vh;
    overflow-y: auto;
    padding: 0;
    text-align: center;
    background-color: #fff;
    font-family: -apple-system,".SFNSText-Regular","San Francisco","Roboto","Segoe UI","Helvetica Neue","Lucida Grande",sans-serif;
    transition: all 0.5s cubic-bezier(.79,.14,.15,.86);
    visibility: hidden;
    transform: translate3d(100%,0,0);
    right: 0;
    box-shadow: 0 4px 60px 0 rgb(0 0 0 / 20%), 0 0 0 transparent;
    &.active {
        transform: translate3d(0,0,0);
        visibility: visible;
    }
}

.header {
    position: relative;
    color: #555;
    display: flex; gap:10px;
    justify-content:space-between;
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    .icon {
        font-size:2rem;
        padding:5px 15px;
        background-color: rgba(0, 0, 0, 0.03);
        border-radius:10px;
        color:var(--primary);
        @media (max-width:600px) {
            padding:5px 10px;
            font-size:1rem;
        }
    }
    .text {
        text-align: left;
        .text-heading {
            font-weight: bold; text-transform: uppercase;
        }
    }
}

.wrapper {
    padding:5px;
}

.message-item {
    border-radius: 10px;
    margin-bottom: 5px;
    .wrapper {
        display: flex; align-items: center;
        text-align: left; gap:10px;
    }
    .avatar {
        --avatar-size:40px;
        width: 40px;
        height: 40px;
        flex: 0 0 auto;
    }
    .info {
        .heading {
            font-size:13px;
            color: rgb(48 48 48);
            line-height: 23px;
        }
        .message {
            color: var(--notification-message-des);
            font-size:14px;
        }
    }
    &:hover {
        background-color: rgba(0,0,0,0.05);
    }
    &.isRead {
        background-color: rgba(0,0,0,0.05);
    }
}

@media(max-width:768px) {
    .notification {
        height: 100vh;
    }
    .wrapper {
        overflow: auto;
        max-height:calc(100vh - 70px);
    }
}