.dashboard {
    --dashboard-radius: 20px;
    --dashboard-card-bg:#F2F6FF;
    --dashboard-box-shadow: 0 0 4px #D4EDFF;

    display: grid;
    grid-template-columns: 1fr 4fr;
    width: 100%;
    padding:20px;
    background-color: #F6FAFD;
    overflow: auto;
    max-height: 93vh;
    @media(max-width: 600px) {
        grid-template-columns: 1fr;
    }
}

.sidebar {
    min-height:calc(100vh - 100px);
    background-color: #fff;
    border-radius: var(--dashboard-radius);
    padding:20px;
    overflow: hidden;
    .account {
        text-align: center;
        margin-bottom: 20px;
        .account-avatar {
            --avatar-size:100px;
            display: inline-block;
        }
        &-name {
            font-weight:bold;
            margin-bottom: 10px;
        }
    }
    @media(max-width: 600px) {
        min-height:0;
    }
}

.content {
    overflow: hidden;
    min-height:calc(100vh - 100px);
    padding:20px;
    .account-action {
        display: flex;
        flex-wrap: wrap;
        gap:10px; margin-bottom: 20px;
    }
    .account {
        font-size: 14px;
        min-width: 255px;
        &-name {
            font-weight:bold;
            font-size: 24px;
            margin-bottom: 10px;
        }
    }
    .action {
        display: flex; gap:10px; justify-content:end; flex-wrap: wrap;
        .action-item {
            text-align: center;
            background-color: #fff;
            border-radius: var(--dashboard-radius);
            padding:10px 20px;
            min-width: 60px;
            box-shadow: var(--dashboard-box-shadow);
            transition: all 0.3s;
            img {
                width: 30px;
            }
            .name {
                font-size: 12px;
                a {
                    color: currentColor;
                }
            }
            &:hover {
                background-color: var(--primary);
                img {
                    filter: grayscale(100%);
                }
                .name {
                    color:#fff;
                }
            }
        }

    }
    @media(max-width:600px) {
        padding:20px 0;
        .action {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            .action-item {
                padding:10px 10px;
                .name {
                    font-size: 11px;
                }
            }
        }
    }
}

.heading {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
}

.card {
    box-shadow: var(--dashboard-box-shadow);
    border-radius: var(--dashboard-radius);
    background-color: var(--dashboard-card-bg);
    padding:20px;
    display: flex;
    gap:20px;
    margin-bottom: 20px;
    position: relative;
    .heading-mini {
        font-weight: 500;
        color:#A0A0A0;
        margin-bottom: 8px;
        font-size: 14px;
    }
    .number {
        font-weight: bold;
        font-size: 18px;
    }
    .bg {
        position: absolute;
        z-index: 0;
        right: 0;
        bottom: 0;

        img {
            max-width: 300px;
            @media (min-width: 600px) {
                max-width: 200px;
            }
            @media (min-width: 700px) {
                max-width: 300px;
            }
            @media (min-width: 1300px) {
                max-width: 400px;
            }
        }
    }
    &-block {
        display: block;
    }
    &-while {
        background-color:#fff;
    }
    &-blue {
        background-color: #82B9FF;
    }
    &-violet {
        background-color: #DEDBFA;
        .heading {
            color:#503E9D!important;
        }
    }
    &-green {
        background-color: #44AFC3;
    }
    &-red {
        background-color: rgb(var(--red-code));
    }
    &-theme {
        background-color: var(--primary)
    }
}

.card-center {
    display: block;
    text-align: center;
}

.card-group {
    .heading {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .info {
        display: grid; grid-template-columns: 1fr 1fr;
        border-radius: var(--dashboard-radius);
        background-color: #fff;
        p {
            padding:5px;
        }
        .revenue {
            border-right: 1px solid var(--dashboard-card-bg);
        }
    }
}

.sidebar .card-money {
    img {
        width: 30px;
    }
    .number {
        font-size: 14px;
    }
}

.card-person {
    display: block;
    height: 235px;
    .info {
        display: flex;
        align-items: stretch;
        background-color: #F6FAFD;
        border-radius: var(--dashboard-radius);
        overflow: hidden;
        max-height: 140px;
        .number-person {
            background-color: #FFB039;
            display: flex;
            align-items: center;
            padding:20px;
            font-size: 24px; color: #fff;
            min-width: 120px;
        }
        img {
            width: 45%;
        }
    }
}

.card-toast {
    display: block;
    position: relative;
    overflow: hidden;
    .heading {
        color:#fff;
    }
    .list-ranks {
        position: relative;
        z-index: 2;
    }
    .user {
        margin-bottom: 20px;
        text-align: center;
        display: flex!important;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .avatar-rank {
            --avatar-size: 50px;
            position: relative; z-index: 1;
            margin-bottom: 10px;
        }
        p {
            color:#ede9e9cc;
        }
        .name {
            color:#fff;
        }
        .revenue {
            color:#fff; font-weight: bold; font-size: 20px;
        }
    }
    .bg-img {
        position: absolute;
        z-index: 0;
        bottom: 10px;

        &-right {
            left: 20px;
        }
        &-left {
            right: 20px;
        }
        img {
            width: 40px;
        }
    }

    .before, .after {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84,
        190px 16.33333px #002bff, -113px -308.66667px #ff009d,
        -109px -287.66667px #ffb300, -50px -313.66667px #ff006e,
        226px -31.66667px #ff4000, 180px -351.66667px #ff00d0,
        -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00,
        -69px -27.66667px #ff0400, -111px -339.66667px #6200ff,
        155px -237.66667px #00ddff, -152px -380.66667px #00ffd0,
        -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00,
        -88px 10.33333px #0d00ff, 112px -309.66667px #005eff,
        69px -415.66667px #ff00a6, 168px -100.66667px #ff004c,
        -244px 24.33333px #ff6600, 97px -325.66667px #ff0066,
        -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff,
        140px -196.66667px #9000ff, 125px -175.66667px #00bbff,
        118px -381.66667px #ff002f, 144px -111.66667px #ffae00,
        36px -78.66667px #f600ff, -63px -196.66667px #c800ff,
        -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff,
        -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2,
        91px -278.66667px #000dff, -22px -191.66667px #9dff00,
        139px -392.66667px #a6ff00, 56px -2.66667px #0099ff,
        -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb,
        -238px -346.66667px #00ff73, 62px -363.66667px #0088ff,
        244px -170.66667px #0062ff, 224px -142.66667px #b300ff,
        141px -208.66667px #9000ff, 211px -285.66667px #ff6600,
        181px -128.66667px #1e00ff, 90px -123.66667px #c800ff,
        189px 70.33333px #00ffc8, -18px -383.66667px #00ff33,
        100px -6.66667px #ff008c;
        animation: 1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    }

    .after {
        animation-delay: 1.25s, 1.25s, 1.25s;
        animation-duration: 1.25s, 1.25s, 6.25s;
    }

    @keyframes bang {
        from {
            box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
            0 0 white, 0 0 white, 0 0 white;
        }
    }

    @keyframes gravity {
        to {
            transform: translateY(200px);
            opacity: 0;
        }
    }

    @keyframes position {
        0%,
        19.9% {
            margin-top: 10%;
            margin-left: 40%;
        }

        20%,
        39.9% {
            margin-top: 40%;
            margin-left: 30%;
        }

        40%,
        59.9% {
            margin-top: 20%;
            margin-left: 70%;
        }

        60%,
        79.9% {
            margin-top: 30%;
            margin-left: 20%;
        }

        80%,
        99.9% {
            margin-top: 30%;
            margin-left: 80%;
        }
    }

}

.card-toDo {
    display: block;
    height: 235px;
    p {
        margin-bottom: 10px;
    }
}

.card-ranks {
    display: block;
    position: relative;
    .heading {
        color: #fff;
    }
}

.card-ranks-medal {
    .medal {
        position: absolute; top:0; left:-10px;
        flex-shrink: 0;
        z-index: 1;
        width: 30px;
        text-align: center;
        display: none;
        &:after, &:before {
            content: "";
            display: block;
            position: absolute;
            border-style: solid;
            border-width: 3px 6px;
            width: 0;
            height: 11px;
            top: 22px;
            z-index: -1;
        }
        &:before {
            border-color: #FC402D #FC402D transparent #FC402D;
            left: 4px;
            transform: rotate(20deg) translateZ(-32px);
        }
        &:after {
            left: 15px;
            border-color: #f31903 #f31903 transparent #f31903;
            transform: rotate(-20deg) translateZ(-48px);
        }
        small {
            display: block;
            font-size: 18px;
            font-weight: 500;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            color: #fff;
            line-height: 28px;
            vertical-align: middle;
            position: relative;
            border-width: .2em;
            border-style: solid;
            z-index: 1;
            box-shadow: inset 0 0 0 #a7b2b8, 1px 1px 0 rgba(0,0,0,.08);
            border-color: #edeff1;
            text-shadow: 1px 1px 0 #98a6ad;
            background: linear-gradient(to bottom right,#d1d7da 50%,#c3cbcf 50%);
        }
    }
    .list-ranks {
        .user:nth-child(1) {
            .medal {
                display: block;
                small {
                    box-shadow: inset 0 0 0 #b67d05, 2px 2px 0 rgba(0, 0, 0, .8);
                    border-color: #fadd40;
                    text-shadow: 0 0 4px #9d6c04;
                    background: linear-gradient(to bottom right, #f9ad0e 50%, #e89f06 50%);
                    &:before {
                        content:"1";
                        display:block;
                        line-height: 24px;
                    }
                }
            }
        }
        .user:nth-child(2) {
            .medal {
                display: block;
                small {
                    box-shadow: inset 0 0 0 #ffb988, 2px 2px 0 rgba(0, 0, 0, 0.8);
                    border-color: #fdc298;
                    text-shadow: 0 0 4px #ffa76a;
                    background: linear-gradient(to bottom right, #f57820 50%, #d75800 50%);
                    &:before {
                        content:"2";
                        display:block;
                        line-height: 24px;
                    }
                }
            }
        }
        .user:nth-child(3) {
            .medal {
                display: block;
                small {
                    &:before {
                        content:"3";
                        display:block;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

.list-ranks {
    position: relative; z-index: 1;
    .user {
        margin-bottom: 20px;
        color:#000;
        position: relative;
        .avatar-rank {
            --avatar-size:50px;
        }
        .name {
            font-size: 14px;
            margin-bottom: 5px;
        }
        .info-more {
            font-size: 10px;
            margin-bottom: 5px;
        }
        .revenue {
            font-size: 13px;
            background-color: #fff;
            border-radius: 10px;
            text-align: left;
            padding:5px 10px;
            color:#000;
        }
        &-white {
            background-color: #fff;
            border-radius:var(--dashboard-radius);
            padding:10px;
            color:#000;
        }
    }
}
