.project-info {
    padding:5px;
    .name {
        font-size:13px;
        font-weight: 500; text-transform: capitalize; margin-right:10px;
    }
    @media(max-width: 600px) {
        display: block;
    }
}
.project-heading {
    display:flex; gap:10px; flex-wrap: wrap; align-items: center;
    @media(max-width: 600px) {
        margin-bottom: 10px;
        span.name {
            width:100%;
        }
    }
}