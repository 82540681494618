.wrapper {
    width:800px;
    padding: 0 50px;
    margin: 0 auto;
    max-width: 100%;
    overflow: auto;
    max-height: 86vh;
    @media (max-width:600px)
    {
        padding: 0 0px;
    }
}
.check-domain-title {
    &-mini {
        margin-top: 20px;
    }
    &-big {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 35px;
        letter-spacing: 1px;
        color: #ff0000;
        position: relative;
        &.green {
            color: #7fca27;
        }
    }
}
.check-domain-wrapper {
    padding-left: 0;
    position: relative;
    margin-bottom: 20px;
    .check-domain-icon {
        position: absolute;
        top: 19px;
        left: 22px;
        font-size: 23px;
        color: #dcdee0;
    }
    input {
        width: 100%;
        border: 0;
        height: 60px;
        border-radius: 25px;
        outline: none;
        padding-left: 60px;
        line-height: 61px;
        box-shadow: 12px 12px 30px 0 rgba(77, 77, 119, .4);
        font-weight: 500;
        color: #8ba2ad;
    }
    .check-domain-button {
        position: absolute;
        top: 10px;
        right: 25px;
    }
    .button-submit {
        background-image: linear-gradient(to right,#ff8585,#ff3d3d);
        border: 0;
        color: #fff;
        height: 40px;
        width: 40px;
        font-size: 14px;
        border-radius: 50px;
        outline: none !important;
        line-height: 40px;
        cursor: pointer;
        box-shadow: 3px 4px 31px 0 rgba(253, 165, 93, 0.54);
        margin-left: 2px;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
}

.domain-card {
    .domain-title {
        font-size:25px;
        font-weight:bold;
        span {
            color: #0d8a19;
        }
    }
    .domain-price {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        &-money {
            color: #FD4549;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
        }
        &-text {
            font-size: 14px;
            line-height: 20px;
            margin-top: 6px;
        }
        &-status {
            font-size: 12px;
            line-height: 16px;
            color: #ffffff;
            background-color: #EA4649;
            padding: 8px 20px;
            font-weight: 500;
            white-space: nowrap;
            border-radius: 20px;
            display: block;
            float: left;
            &.success {
                background-color: #7fca27;
            }
        }
    }
}